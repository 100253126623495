import React from 'react';
import "./videodemo.css";

const Videodemo = () => {
  return (
    <div className='supportai__videodemo' id='demo'>
      <div className='supportai__videodemo-header'>
        <h1>Video Demo</h1>
        <p>In this video we go through the simple process of setting up the chatbot.</p>
      </div>
      <div className="supportai__videodemo-container">
        <iframe
          className="video-frame"
          src="https://www.youtube.com/embed/example"
          title="Video Demo"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Videodemo;
