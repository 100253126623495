import React from 'react'
import { Navbar, Footer } from "../../components"
import { Header, Content } from "../../containers"
import "./home.css"

const iframeStyle = {
  position: "fixed",
  border: 'none',
  width: '100%',
  height: '100%',
};

const Home = () => {
  return (
    <div>
      <div className='chatbot'>
        <iframe
            src="http://localhost:3002/chatbot"
            title="SupportAI Chatbot"
            style={iframeStyle}
        ></iframe>
      </div>
      <Navbar />
      <Header />
      <Content />
      <Footer />
    </div>
  )
}

export default Home
