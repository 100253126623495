import React, { useState } from 'react';
import "./App.css";
import { MainRoutes } from './routes';

const App = () => {

  return (
      <div id='top'>
        <MainRoutes />
      </div>
  );
};

export default App;
