import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "./navbarstatic.css";

const Menu = ({ darkThemeEnabled }) => {
  
  // Get the current URL of the page
  const [currentUrl, setCurrentUrl] = useState(window.location.href);

  useEffect(() => {
    // Update the current URL when the URL changes
    window.addEventListener("hashchange", () => {
      setCurrentUrl(window.location.href);
    });
  });


  return (
    <>
      <p>
        <a href="#home" className={currentUrl.endsWith("#home") ? "active" : ""}>
          Home
        </a>
      </p>
      <p>
        <a href="#about" className={currentUrl.endsWith("#about") ? "active" : ""}>
          About
        </a>
      </p>
      <p>
        <a href="#examples" className={currentUrl.endsWith("#examples") ? "active" : ""} >
          Examples
        </a>
      </p>
      <p>
        <a href="#contact" className={currentUrl.endsWith("#contact") ? "active" : ""} >
          Contact
        </a>
      </p>
    </>
  );
};
// BEM --> Block Element Modifier

const NavbarStatic = () => {

  return (
    <div className="supportai__navbarstatic">
      <div className="supportai__navbarstatic-links">
        <Link to="/">
          <h1>Aide</h1>
        </Link>
        <div className="supportai__navbarstatic-links_container">
          <Menu/>
        </div>
      </div>
    </div>
  );
};

export default NavbarStatic;
