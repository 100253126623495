import React from 'react'
import './card.css'

const Card = ({ title, text, img }) => {
  return (
    <div className='supportai__card'>
      <div className='supportai__card-body'>
        <div className='image-container'>
          <img src={img} alt={title} />
        </div>
        <div className='supportai__card-text'>
            <h3>{title}</h3>
            <p>{text}</p>
        </div>
      </div>
    </div>
  )
}

export default Card
