import React, { useEffect } from "react";
import "./content.css";
import Card from "../../components/Card/Card";
import Word from "../../assets/word-example.png";
import Chat from "../../assets/chat-demo.png";
import { Videodemo } from "../../components";

const Content = () => {
  useEffect(() => {
    console.log("observer attached");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        console.log(entry.target);
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-in", "visible");
          observer.unobserve(entry.target);
        }
      });
    });
    const fadeIns = document.querySelectorAll(".fade-in");
    fadeIns.forEach((fadeIn) => {
      observer.observe(fadeIn);
    });
  }, []);

  return (
    <div className="supportai__about" id="about">
      <div className="supportai__about-info fade-in">
        <h1>Save time and resources</h1>
        <p>
          Omium lets you train and customize your own chatbot that is tailored
          for providing exceptional support to your customers.
          <br />
          With Omium, you can easily train your chatbot to handle support
          inquiries on your internal systems,
          <br />
          freeing up your team's time to focus on more complex and high-value
          tasks.
        </p>
      </div>
      <Videodemo />
      <div className="supportai__about-examples" id="examples">
        <h1>Example</h1>
        <p>
          In the admin dashboard, you can upload documents that the chatbot
          will use to answer questions.
          <br />
          By uploading a document, Omium gains the ability to provide accurate
          responses to any related questions.
          <br />
          For instance, if you upload a document containing information about
          your internal systems, the chatbot will search
          <br />
          through that document for relevant answers when responding to user
          queries.
        </p>
        <div className="supportai__about-examples_cards fade-in">
          <Card
            img={Word}
            title="Uploading documents"
            text="This Word document can be used as data, allowing customers to ask questions about the products, pricing, return policies, and shipping information."
          />
          <Card
            img={Chat}
            title="Answering questions"
            text="The bot can provide quick and accurate answers to customer inquiries regarding the data it gathered from the Word document, improving the overall shopping experience and increasing customer satisfaction."
          />
        </div>
      </div>
      <div class="supportai__about-faq" id="faq">
        <h1>Frequently Asked Questions</h1>
        <ul>
          <li>
            <h3>What is Omium?</h3>
            <p>
              Omium is an advanced AI-powered chatbot that can be integrated on any website,
              answering questions and support inquiries on what its been trained on.
            </p>
          </li>
          <li>
            <h3>How does Omium work?</h3>
            <p>
              Omium lets you upload your own documents and links to train your
              own custom chatbot. The chatbot understands and responds to user
              queries, providing helpful and accurate answers.
            </p>
          </li>
          <li>
            <h3>Does it use ChatGPT?</h3>
            <p>Yes, we use ChatGPT's API model gpt-3.5-turbo.</p>
          </li>
          <li>
            <h3>Can it handle multiple languages?</h3>
            <p>
              Yes, the chatbot is designed to support multiple languages,
              allowing users to interact with the chatbot in their preferred
              language.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Content;
