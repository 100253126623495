import React from 'react'
import "./privacypolicy.css"
import { Article } from '../../components'
import { NavbarStatic } from '../../containers'

const PrivacyPolicy = () => {
  return (
    <div className='supportai__privacypolicy' id='home'>
            <NavbarStatic />
        <div className='supportai__privacypolicy-article'>
            <Article />
        </div>
    </div>
  )
}

export default PrivacyPolicy
