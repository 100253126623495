import React from 'react'
import { Link } from 'react-router-dom';
import "./footer.css"

const Footer = () => {
  return (
    <div className='supportai__footer' id="contact">
      <div className='supportai__footer-content'>
        <h1>Omium</h1>
        <p>Norway</p>
        <Link to="/privacypolicy">
          <p>Privacy Policy</p>
        </Link>
        <div className='supportai__footer-content_email'>
            <a>contact@omium.co</a>
        </div>
      </div>
    </div>
  )
}

export default Footer
