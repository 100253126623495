import React, { useState, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import "./navbar.css";

const Menu = ({ darkThemeEnabled }) => {
  
  // Get the current URL of the page
  const [currentUrl, setCurrentUrl] = useState(window.location.href);

  useEffect(() => {
    // Update the current URL when the URL changes
    window.addEventListener("hashchange", () => {
      setCurrentUrl(window.location.href);
    });
  });


  return (
    <>
      <p>
        <a href="#home" className={currentUrl.endsWith("#home") ? "active" : ""}>
          Home
        </a>
      </p>
      <p>
        <a href="#demo" className={currentUrl.endsWith("#demo") ? "active" : ""}>
          Demo
        </a>
      </p>
      <p>
        <a href="#examples" className={currentUrl.endsWith("#examples") ? "active" : ""} >
          Examples
        </a>
      </p>
      <p>
        <a href="#faq" className={currentUrl.endsWith("#faq") ? "active" : ""} >
          FAQ
        </a>
      </p>
    </>
  );
};
// BEM --> Block Element Modifier

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 10) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  

  return (
    <div className={`portfolio__navbar${scrolling ? " scrolling" : ""}`}>
      <div className="portfolio__navbar-links">
        <div className="portfolio__navbar-links_logo">
          <h1 href="#">Omium</h1>
        </div>
        <div className="portfolio__navbar-links_container">
          <Menu/>
        </div>
        <div className="portfolio__navbar-links_login">
          <h1>Sign in</h1>
        </div>
      </div>
    
      
    </div>
  );
};

export default Navbar;
