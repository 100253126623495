import React, { useState, useEffect } from 'react';
import './header.css';
import fade from '../../assets/fade.png';
import demo from '../../assets/SupportAI-demo.png';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { db } from "../../firebase"
import { uid } from "uid"
import { set, ref } from "firebase/database"
import { Link } from 'react-router-dom';

const Header = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [agreedToPrivacyPolicy, setAgreedToPrivacyPolicy] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [message, setMessage] = useState("");

  useEffect(() => {
    setAlertOpen(false);
  }, [])

  useEffect(() => {
    if (alertOpen) {
      const timeoutId = setTimeout(() => {
        setAlertOpen(false);
      }, 10000);

      return () => clearTimeout(timeoutId);
    }
  }, [alertOpen]);

  const handleEmailInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleAgreeToPrivacyPolicyChange = (event) => {
    setAgreedToPrivacyPolicy(event.target.checked);
  };

  useEffect(() => {
    if (!showPopup) {
      setAgreedToPrivacyPolicy(false);
    }
  }, [showPopup]);
  

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const writeToDatabase = () => {
    if (!email) {
      setAlertType("error");
      setAlertOpen(true);
      return;
    }

    const uuid = uid();
    const emailKey = `email-${uuid}`;
    set(ref(db, `emails/${emailKey}`), {
      email: email,
    })

    .then(() => {
      setAlertType("success");
      setAlertOpen(true);
})
    .catch((error) => {
      setMessage("Agree to the privacy policy to continue");
      setAlertType("error");
      setAlertOpen(true);
});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email.trim() === '') {
      setAlertType('error');
      setMessage('Please enter a valid email address');
      setAlertOpen(true);
      return;
    }
    if (!validateEmail(email)) {
      setAlertType("error");
      setMessage("Invalid email address");
      setAlertOpen(true);
      return;
    }
    if (agreedToPrivacyPolicy) {  
      // Code to send email to backend or email service provider
      writeToDatabase();
      console.log(`Email ${email} submitted`);
      setEmail(''); // Clear email input
      setShowPopup(false);
    } else {
      setAlertType('error');
      setMessage('Agree to the privacy policy to continue');
      setAlertOpen(true);
    }
  };
  

  const handleAlertClose = () => {
    setTimeout(() => {
      setAlertOpen(false);
    }, 5000);
  };   

  document.addEventListener('mousemove', parallax);

  function parallax(e) {
    document.querySelectorAll('.portfolio__header-content img').forEach(function (move) {
      var moving_value = move.getAttribute('data-value');
      var x = (e.clientX * moving_value) / 300;
      var y = (e.clientY * moving_value) / 300;

      move.style.transform = 'translateX(' + x + 'px) translateY(' + y + 'px';
    });
  }

  return (
    <div className='portfolio__background'>
      <img src={fade} className='fade' />
      <div className='portfolio__header section__padding' id='home'>
        <div className='portfolio__header-content'>
          <img src={demo} />
          <div className='portfolio__header-content__introduction'>
            <h1>
              GPT-3 chatbots
              <br />
              trained on your 
              <br />
              own data.
            </h1>
            <p>
              No coding, easy website integration.
              <br />
              <br />
              Get notified when we launch.
            </p>
            <a href='#' onClick={() => setShowPopup(true)}>
              Notify Me
            </a>
          </div>
        </div>
        {alertOpen && 
          <Alert className='alert' variant="filled" open={alertOpen} onClose={handleAlertClose} severity={alertType}>
            {alertType === "success" ? "Great! We will notify you when we launch." : 
            alertType === "error" ? 
              <>
                <AlertTitle>Error</AlertTitle>
                {message}
                </>
              : "Error Occured"}
          </Alert>    
        }
      </div>
      {showPopup && (
        <div className='popup'>
          <div className='popup__content'>
            <span className='close' onClick={() => setShowPopup(false)}>
              &times;
            </span>
            <h2>Get notified</h2>
            <p>Fill in your email address to get notified when we launch!</p>
            <form onSubmit={handleSubmit}>
              <label htmlFor='email'>Email</label>
              <input type='email' id='email' name='email' required value={email} onChange={handleEmailInputChange} />
                <label class="container">
                I agree to the{' '}
                <Link to="/privacypolicy">
                  <a className='link' href='https://example.com/privacy-policy' target='_blank' rel='noopener noreferrer'>
                    privacy policy
                  </a>
                </Link>
                  <input type="checkbox" required  onChange={handleAgreeToPrivacyPolicyChange}/>
                  <span class="checkmark" required></span>
                </label>
              <button onClick={handleSubmit} type='submit'>Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
