import React from 'react'
import "./article.css"

const Article = () => {
    return (
        <div className='supportai__article'>
          <div className='supportai__article-heading content__padding'>
            <>
              <h1>Privacy Policy</h1>
            </>
            <p></p>
          </div>
          <div className='supportai__article-content content__padding'>
              <meta name="description" content=""></meta>
              <p>
                We at Aide value your privacy and are committed to protecting your personal information. This privacy policy outlines how we collect, use, and store your personal information.
                <br/><br/><span className='h2'>Collection of Personal Information</span>
                <br/><br/>We collect your email address when you sign up to receive notifications about the launch of our product. We only collect this information with your consent, and we do not collect any additional personal information.
                <br/><br/><span className='h2'>Use of Personal Information</span>
                <br/><br/>We use your email address to send you notifications about the launch of our product. We do not use your email address for any other purposes, and we do not share your email address with any third parties.
                <br/><br/><span className='h2'>Storage of Personal Information</span>
                <br/><br/>We store your email address securely in our database. We take all reasonable precautions to protect your personal information from unauthorized access, use, or disclosure.
                <br/><br/><span className='h2'>Your Rights</span>
                <br/><br/>You have the right to request access to the personal information we have collected about you. You also have the right to request that we correct any errors or omissions in your personal information.
                <br/><br/><span className='h2'>Contact Us</span>
                <br/><br/>If you have any questions or concerns about our privacy policy, please contact us at [Contact Email].
            </p>
            <div className='supportai__article-image'>
            </div>
          </div>
        </div>
      )
    }
    
    export default Article
    
